import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { TabLinks } from '../../../components/TabLinks';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "data-visualization",
      "style": {
        "position": "relative"
      }
    }}>{`Data visualization`}</h1>
    <p>{`Data visualization consists of presenting information and data graphically to synthesizing complex information and understanding patterns and exceptions presented in the data. Data visualization should not be used as decoration.`}</p>
    <TabLinks items={[{
      title: 'Models',
      href: '/design-guidelines/data-visualization/models'
    }, {
      title: 'Usage',
      href: '/design-guidelines/data-visualization/usage'
    }]} mdxType="TabLinks" />
    <h2 {...{
      "id": "which-chart-to-use",
      "style": {
        "position": "relative"
      }
    }}>{`Which chart to use`}</h2>
    <p>{`The type of chart you use depends primarily on two things: the data you want to communicate, and what you want to convey about that data. These guidelines provide descriptions of various different types of charts and their use cases.`}</p>
    <h3 {...{
      "id": "comparison",
      "style": {
        "position": "relative"
      }
    }}>{`Comparison`}</h3>
    <p>{`Compare data between multiple distinct categories.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/bar.png",
        "alt": "Bar chart"
      }}></img>{` `}<img parentName="p" {...{
        "src": "/image/stacked-bar.png",
        "alt": "Stacked bar chart"
      }}></img>{` `}<img parentName="p" {...{
        "src": "/image/grouped-bar.png",
        "alt": "Grouped bar chart"
      }}></img>{` `}<img parentName="p" {...{
        "src": "/image/bubble-plot.png",
        "alt": "Bubble plot"
      }}></img>{` `}<img parentName="p" {...{
        "src": "/image/line.png",
        "alt": "Line chart"
      }}></img></p>
    <h3 {...{
      "id": "change-over-time",
      "style": {
        "position": "relative"
      }
    }}>{`Change over time`}</h3>
    <p>{`Show data over a period of time, such as trends or comparisons across multiple categories.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/bar.png",
        "alt": "Bar chart"
      }}></img>{` `}<img parentName="p" {...{
        "src": "/image/stacked-bar.png",
        "alt": "Stacked bar chart"
      }}></img>{` `}<img parentName="p" {...{
        "src": "/image/area.png",
        "alt": "Area chart"
      }}></img>{` `}<img parentName="p" {...{
        "src": "/image/line.png",
        "alt": "Line chart"
      }}></img></p>
    <h3 {...{
      "id": "relationship--distribution",
      "style": {
        "position": "relative"
      }
    }}>{`Relationship & Distribution`}</h3>
    <p>{`Show the relationship between two or more variables or the frequency at which data occurs.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/bubble-plot.png",
        "alt": "Bubble plot"
      }}></img>{` `}<img parentName="p" {...{
        "src": "/image/scatter-plot.png",
        "alt": "Scatter plot"
      }}></img></p>
    <h3 {...{
      "id": "control",
      "style": {
        "position": "relative"
      }
    }}>{`Control`}</h3>
    <p>{`Used in processes to track the evolution of a variable against one or more values of reference.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/line-control.png",
        "alt": "Line control chart"
      }}></img>{` `}<img parentName="p" {...{
        "src": "/image/area-control.png",
        "alt": "Area control chart"
      }}></img></p>
    <h3 {...{
      "id": "part-to-whole",
      "style": {
        "position": "relative"
      }
    }}>{`Part-to-whole`}</h3>
    <p>{`Show how partial elements add up to a total.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/pie.png",
        "alt": "Pie chart"
      }}></img>{` `}<img parentName="p" {...{
        "src": "/image/stacked-bar.png",
        "alt": "Stacked bar chart"
      }}></img>{` `}<img parentName="p" {...{
        "src": "/image/stacked-area.png",
        "alt": "Stacked area chart"
      }}></img></p>
    <h2 {...{
      "id": "dashboards",
      "style": {
        "position": "relative"
      }
    }}>{`Dashboards`}</h2>
    <h3 {...{
      "id": "operational-dashboard",
      "style": {
        "position": "relative"
      }
    }}>{`Operational dashboard`}</h3>
    <p>{`Use it to provide information that demands immediate user action and time-sensitive data, such as data monitoring. This kind of dashboard is constantly updating.`}</p>
    <h3 {...{
      "id": "analytical-dashboard",
      "style": {
        "position": "relative"
      }
    }}>{`Analytical dashboard`}</h3>
    <p>{`Use analytical dashboards to help users take future actions, investigate or analyze data. It updates less frequently than the operational dashboard (eg: 1 time a day). Learn more about dashboard types at `}<a parentName="p" {...{
        "href": "https://www.nngroup.com/articles/dashboards-preattentive/"
      }}>{`Nielsen and Norman group website`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      